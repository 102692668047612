require('team-admin')
angular.module('team-admin')
  .factory('Game', function(DS, paginate, moment, timezone, i18ng, _, getEnum, Opponent, Venue, Subvenue) {

    var Game = DS.defineResource({
      name: 'games',
      endpoint: '/v3/events',
      basePath: DS.adapters.se_api.defaults.basePath, // workaround for js-data-angular fallbacks
      httpConfig: DS.adapters.se_api.defaults.httpConfig, // workaround for js-data-angular fallbacks
      defaultAdapter: 'se_api',
      computed: {
        unique_id: ['id', _.identity],
        contextName: ['id', _.constant('games')],
        gaLabelName: ['id', _.constant('Game')],
        contextParams: ['id', function(id) { return { gameId: id } }],
        eventStatus: ['game_details.status', _.identity],
        canSchedule: ['all_day_event', 'game_details.status', function(all_day_event, status) { return status === 'scheduled' && !all_day_event }],
        canDelete: ['shared_event', 'is_affiliated', function(se, affiliated) { return affiliated ? false : !se?.read_only }]
      },
      relations: {
        hasOne: {
          venues: {
            localField: 'venue',
            localKey: 'venue_id'
          },
          subvenues: {
            localField: 'subvenue',
            localKey: 'subvenue_id'
          }
        },
        hasMany: {
          event_rsvps: {
            localField: 'event_attendees',
            foreignKey: 'event_id'
          }
        }
      },
      omit: ['date', 'end_time', 'start_time', 'event_id', 'unique_id', 'contextName', 'gaLabelName', 'contextDetail', 'place', 'venue_address'], // strip temporary properties before save
      methods: {

        homeTeamId: function(homeTeamId) {
          var team1 = this.game_details.team_1 || {}
          var team2 = this.game_details.team_2 || {}

          //setter
          if (arguments.length) {
            team1.is_home_team = team1.id === homeTeamId
            team2.is_home_team = team2.id === homeTeamId
          }

          // getter
          else {
            if (team1.is_home_team) return team1.id
            if (team2.is_home_team) return team2.id
            return null
          }

        },

        otherTeam: function(team) {
          if (!team) return {}
          var team1 = this.game_details.team_1 || {}
          var team2 = this.game_details.team_2 || {}
          if (team1 && team1.id === team.id) return team2
          if (team2 && team2.id === team.id) return team1
          return {}
        },

        shortTitle: function(team) {
          if (team && this.game_details.team_1 && this.game_details.team_2) {
            var team_1 = this.game_details.team_1, team_2 = this.game_details.team_2
            if (team_1.id === team.id) return (team_1.is_home_team ? 'vs. ' : '@ ') + team_2.name
            if (team_2.id === team.id) return (team_2.is_home_team ? 'vs. ' : '@ ') + team_1.name
          }
          return this.title
        },

        teamsLocked: function() {
          return _.indexOf(['in_progress', 'completed', 'final', 'postponed'], this.game_details.status) >= 0 || this.is_affiliated
        },

        scheduleLocked: function() {
          return _.indexOf(['cancelled', 'postponed', 'delayed'], this.game_details.status) >= 0 || this.is_affiliated
        },

        translatedStatus: function() {
          return i18ng.t('GAME.STATUS.' + this.game_details.status)
        },

        unspecifiedTime: function() {
          return this.tbd_time
        },

        locationDisplay: function(format = 'short') {
          return _.compact([this.location_name || this.location_address, this.location_description]).join(' - ') +
            (format === 'long' && this.location_name ? '\n' + this.location_address : '')
        },

        unspecifiedTimeLabel: function() {
          return 'SCHEDULE.tbd_time'
        }

      },
      beforeUpdate: beforeSave,
      beforeCreate: beforeSave,
      afterUpdate: afterSave,
      afterCreate: afterSave,
      beforeInject: beforeInject
    })

    Game.locationProtocols = ['http://', 'https://']

    // Set temporary date/time properties (used for template bindings)
    function setTimeData(game) {
      var tz = game.local_timezone || timezone.current.name
      var startDateTime = moment.tz(game.start_date_time, tz)

      // TODO: remove sync of start/end time once we have UI for setting end time
      var endDateTime = moment.tz(game.end_date_time, tz)

      game.date = startDateTime.format('YYYY-MM-DD')
      game.start_time = startDateTime.format('HH:mm')
      game.end_time = endDateTime.format('HH:mm')
    }

    function beforeInject(options, data) {
      var arr = Array.isArray(data) ? data : [data]
      arr.forEach(parse)
    }

    function beforeCreateInstance(options, data) {
      parse(data)
    }

    async function beforeSave(options, data, callback) {

      // Convert New Team Data
      if (data.game_details.team_2.id === 'new') {
        Opponent.ejectAll()
        data.game_details.team_2.id = (await Opponent.create({
          name: data.game_details.team_2.name
        }, {
          endpoint: `/v3/teams/${ data.game_details.team_1.id }/opponents`
        })).id
      }

      data.type = 'game' // needs type instead of event_type for POST/PUT

      // update date properties
      var tz = data.local_timezone ||= timezone.current.name
      data.tbd_time ||= false
      data.with_notification ||= false

      // set start time to midnight for TBD games (tbd_time flag)
      var startTime = data.tbd_time ? '00:00' : data.start_time
      data.start_date_time = moment.tz(data.date + ' ' + startTime, tz).format() || null

      // 'location_address' is required if 'location_url' is being sent
      if (data.location_url && !data.location_address) data.location_address = i18ng.t('GAME.default_location_name')

      // TODO: remove sync of start/end time once we have UI for setting end time
      // if (data.all_day_event) data.end_date_time = null
      // else if (data.end_time) data.end_date_time = moment.tz(data.date +" "+ data.end_time, tz).format() || null
      data.end_date_time = moment.tz(data.start_date_time, tz)
        .add(data.duration_hours, 'hours')
        .add(data.duration_minutes, 'minutes')
        .format()

      callback(null, data)
    }

    function afterSave(options, data, callback) {
      callback(null, parse(data))
    }

    function parse(data) {
      setTimeData(data)
      data.venue_id = +data.venue_id || null
      data.subvenue_id = +data.subvenue_id || null
      return data
    }

    Game.parseCalendar = Game.inject

    Game.setupTranslation = function(scope) {
      scope.temperatureUnits = getEnum('ENUMS.temperature_units')
      scope.windSpeedUnits = getEnum('ENUMS.wind_speed_units')
      scope.windDirections = getEnum('ENUMS.wind_directions')
      scope.gameStatuses = getEnum('ENUMS.game_statuses')
    }

    Game.setTimeData = setTimeData

    return paginate(Game)
  })
