require('team-admin')
angular.module('team-admin')
  .directive('eventInformationReadonly', function() {
    return {
      restrict: 'A',
      scope: {
        isGame: '@',
        isEvent: '@',
        resource: '=',
        originalInvitations: '=invitations',
        team: '='
      },
      template: require('./event-information-readonly.html'),
      controller: function(
        $scope, $sce, selectHelper, $log, renderContext, $rootScope, currentUser, dialog, showError, debounceCallback, Calendar, $q, Alerts, EventRsvp, launchDarklyFlags, Subvenue, Organization) {
        // Prevent alert on game-details
        $scope.$watch('rsvpForm.$dirty', renderContext.requireConfirmation($scope))
        $scope.$watchCollection('resource', getTeamBag)

        function getTeamBag() {
          $scope.extra = $scope.teamBag = _.find($scope.resource.principals, { id: $scope.team.id })?.extended_attributes || {}
          if ($scope.teamBag?.arrival_time) calculateArrivalTime()
        }

        currentUser.getPersonas()

        this.$onInit = function() {
          // Preprocess description
          if ($scope.resource.description) {
            $scope.descriptionHtml = $sce.trustAsHtml($scope.resource.description)
          }

        }

        function calculateArrivalTime() {
          var timeInMilli = Date.parse($scope.resource.start_date_time)
          $scope.arrivalTime = new Date(timeInMilli - $scope.teamBag.arrival_time * 60000)
        }

        async function getOrgSubvenues() {
          const subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' }).filter((i) => 'venue_name' in i)
          return subvenues
        }

        $scope.hasExtra = function(extra) {
          return (extra.attendance || extra.temperature || extra.wind_speed || extra.weather_conditions || extra.ticket_column_link || extra.broadcast_column_link)
        }

        var loading = $scope.setAs('loading')

        /** @deprecated */
        // TODO: Remove when Game modal is done and deployed to production
        const editEventUsingOldModal = async(type = 'game') => {
          dialog.confirm({
            directive: type + '-information',
            scope: $scope,
            attrs: {
              team: $scope.team,
              resource: $scope.resource,
              changeRange: $scope.resource.recurInterval && $scope.resource.recurInterval !== 'none' && await getChangeRange(),
              invitations: $scope.originalInvitations
            }
          })
            .then($scope.addEvent)
        }

        $scope.editGameModal = async function(type = 'game') {
          if (!launchDarklyFlags.gameAddEditModal) {
            return editEventUsingOldModal(type)
          }

          const modal = document.createElement('se-game-add-edit-modal')
          modal.seGameId = $scope.resource.id
          modal.seDataTriggerId = 'se-edit-game-tc'
          document.body.append(modal)
          modal.addEventListener('seAfterClose', () => modal.remove())
          modal.addEventListener('seOnSuccess', () => $rootScope.$emit('event:save_recurring', $scope.resource))
          modal.addEventListener('saveSubvenue', async () => {
            const subvenues = await getOrgSubvenues();
            modal.seSubvenues = JSON.stringify(subvenues);
          });

          const orgId = $scope.team.org_details.id
          let subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' })
          if($scope.resource?.subvenue_id && !subvenues.some(item => $scope.resource.subvenue_id === item.id)) {
            const missingVenue = await Subvenue.find($scope.resource.subvenue_id) || null
            subvenues.push(missingVenue)
          }
          const org = await Organization.findPublic(orgId)
          modal.seTeam = JSON.stringify($scope.team)
          modal.seOrg = JSON.stringify(org)
          modal.seSubvenues = JSON.stringify(subvenues.filter((i) => 'venue_name' in i))

          modal.seOpen = true
        }

        $scope.newEditEventModal = async function() {

          const modal = document.createElement('se-event-add-edit-modal')
          modal.seEventId = $scope.resource.id
          modal.seDataTriggerId = 'se-edit-event-tc'
          document.body.append(modal)
          modal.addEventListener('seAfterClose', () => modal.remove())
          modal.addEventListener('seOnSuccess', () => $rootScope.$emit('event:save_recurring', $scope.resource))
          modal.addEventListener('saveSubvenue', async () => {
            const subvenues = await getOrgSubvenues();
            modal.seSubvenues = JSON.stringify(subvenues);
          });

          const orgId = $scope.team.org_details.id
          let subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' })
          if($scope.resource?.subvenue_id && !subvenues.some(item => $scope.resource.subvenue_id === item.id)) {
            const missingVenue = await Subvenue.find($scope.resource.subvenue_id) || null
            subvenues.push(missingVenue)
          }
          const org = await Organization.findPublic(orgId)

          modal.seTeam = JSON.stringify($scope.team)
          modal.seOrg = JSON.stringify(org)
          modal.seSubvenues = JSON.stringify(subvenues.filter((i) => 'venue_name' in i) || [])

          modal.seOpen = true
        }

        function getChangeRange() {
          return dialog.confirm({
            directive: 'select-change-range',
            attrs: {
              event: $scope.resource,
              buttonLabel: 'SCHEDULE.EDIT_EVENT.continue'
            }
          })
        }

        $scope.addEvent = function(event) {
          $scope.events.push(event)
          loadEvents()
        }

        $scope.hasInvite = function(personaId) {
          return _.some($scope.originalInvitations, { persona: { id: personaId } })
        }

        var findAll = debounceCallback(Calendar.findAll)
        function loadEvents() {
          loading(true)
          findAll({
            team_id: $scope.team.id,
            start_date_time: $scope.viewDate.clone().startOf('month').format('YYYY-MM-DD'),
            end_date_time: $scope.viewDate.clone().endOf('month').format('YYYY-MM-DD'),
            order_by: 'start_date_time',
          })
            .then($scope.setAs('events'))
            .catch(showError)
            .finally(loading.bind(null, false))
        }

        $scope.cancel = function() {
          goBack()
        }

        function goBack() {
          renderContext.requireConfirmation(false)
          renderContext.backto(renderContext.schedule.next)
        }

        $scope.selectedPlayerRsvps = selectHelper.bind($scope, 'invitations')
        $scope.loading = true

        loadPlayers()

        $scope.$watchGroup(['originalInvitations', 'originalInvitations.length', 'originalPlayers', 'originalPlayers.loading'], function(data) {
          var [invitations, invitationsLength, players, playersLoading] = data

          if (!invitations || !players || playersLoading) return

          var emptyMessagePrefix = 'SCHEDULE.EVENT_RSVPS.'

          var playerByPersona = _.indexBy(players, 'persona.persona_id')

          invitations = _.filter(invitations, function(value) {
            return !!playerByPersona[value.persona_id]
          })

          $scope.loading = false
          $scope.invitations = angular.copy(invitations)
          $scope.players = angular.copy(players)
          $scope.playerByPersona = playerByPersona
          $scope.hasMembers = !!players.length
          $scope.emptyMessagePrefix = emptyMessagePrefix + ($scope.hasMembers ? 'EMPTY' : 'EMPTY_TEAM')

          updateSavedRsvps()
          updateInviteOrder($scope.invitations)
        })

        var RESPONSE_ORDER = ['yes', 'no', 'maybe']
        var inviteOrderMap = {}

        $scope.savedInviteOrder = function(invite) {
          return inviteOrderMap[invite.id]
        }

        function updateSavedRsvps() {
          $scope.savedRsvps = {}
          _.each($scope.invitations, function(invite) {
            $scope.savedRsvps[invite.id] = angular.copy(invite)
          })
        }

        function updateInviteOrder(invitations) {
          _.each(invitations, function(invite) {
            var response = invite?.response
            var responseIndex = RESPONSE_ORDER.indexOf(response)
            if (responseIndex === -1) responseIndex = RESPONSE_ORDER.length // not found (pending) go last
            var orderKey = responseIndex + invite.player.name
            inviteOrderMap[invite.id] = orderKey
          })
        }

        $scope.save = function() {
          return saveRsvps().then(saveRsvpSuccess, saveRsvpError)
        }

        function saveRsvps(newInvitations) {
          if (!$scope.savedRsvps || !$scope.invitations) return $q.when()
          if (_.isArray(newInvitations)) $scope.newInvitations = newInvitations // created when recurring events are modified to form a new event
          return $q.all(_.map($scope.invitations, saveRsvp))
        }

        function changedRsvp(invite) {
          var current = invite
          var saved = $scope.savedRsvps[invite.id]
          return current && (!saved || !angular.equals(saved, current))
        }

        function saveRsvp(invite) {
          if (!changedRsvp(invite)) return
          return EventRsvp.create({
            ..._.pick(invite, 'event_id', 'persona_id', 'response', 'note'),
            team_id: $scope.team.id
          })
        }

        function saveRsvpSuccess() {
          var originals = $scope.originalInvitations
          originals.length = 0
          originals.push(...$scope.invitations)
          goBack()
          Alerts.success('SCHEDULE.EVENT_RSVPS.save_success')
        }

        function saveRsvpError() {
          $scope.failed = true
        }

        function loadPlayers() {
          return $scope.team.getRoster()
            .then($scope.setAs('originalPlayers'), $log.error)
        }

      }
    }
  })
