require('team-admin')
angular.module('team-admin')
  .directive('scheduleRsvps', function() {
    return {
      restrict: 'A',
      scope: {
        team: '='
      },
      template: require('./schedule-rsvps.html'),

      controller: function($scope, $element, $q, $rootScope, $routeParams, $filter,
        $location, $log, $timeout, Calendar, Player, preloadScript,
        EventRsvp, Alerts, selectHelper, snAsync, dialog, moment,
        snGlobals, i18ng, renderContext, featureToggles, debounceCallback, currentUser, ENV, launchDarklyFlags, Organization, Subvenue) {
        $scope.loading = true
        $scope.$watch('rsvpsForm.$dirty', renderContext.requireConfirmation($scope))
        $scope.selectedEvents = selectHelper.bind($scope, 'filteredEvents')
        $scope.selectedMembers = selectHelper.bind($scope, 'members')
        currentUser.getPersonas()

        preloadScript(`${ ENV.urls.sportAdminElements }/event-add-edit-modal-element/element.js`)
        preloadScript(`${ ENV.urls.sportAdminElements }/game-add-edit-modal-element/element.js`)

        var oldGroup = []
        $scope.$watchGroup(['team.id', 'viewDate'], function(group) {
          if (_.all(group)) {
            var teamIdChanged = group[0] !== oldGroup[0]
            var viewDateChanged = group[1] !== oldGroup[1]

            load(!$scope.members || teamIdChanged)
          }
          oldGroup = _.clone(group)
        })

        function load(reloadPlayers) {
          $scope.loading = true
          $scope.rsvpsForm.$setPristine()
          var promises = [loadEvents()]
          if (reloadPlayers) promises.push(loadPlayers())
          $q.all(promises).finally($scope.setAs('loading', false))
        }

        var findAll = debounceCallback(Calendar.findAll)
        function loadEvents() {
          return findAll({
            start_date: $scope.viewDate.clone().startOf('month').format(),
            end_date: $scope.viewDate.clone().endOf('month').endOf('day').format(),
            order_by: 'start_date',
            show_event_attendees: 1
          }, { endpoint: `/v3/calendar/team/${ $scope.team.id }`, load: 'all' })
            .then(_.partial($filter('orderBy'), _, Calendar.orderByTime, false))
            .then($scope.setAs('filteredEvents'))
            .then($scope.setAs('events'))
            .then(loadInvites)
            .catch($log.error)
        }

        function loadInvites() {
          $scope.savedRsvps = {}
          $scope.eventInvitations = []
          $scope.invitesByEvent = {}
          _.each($scope.events, function(event) {
            _.each(event.event_attendees, indexInviteForEvent(event))
          })
        }

        function loadPlayers() {
          return $scope.team.getRoster()
            .then($scope.setAs('members'), $log.error)
        }

        $scope.cancel = function() {
          var changed = _.filter($scope.eventInvitations, changedRsvp)
          _.each(changed, function(invite) {
            _.extend(invite, $scope.savedRsvps[invite.id])
          })
          $scope.rsvpsForm.$setPristine()
        }

        $scope.save = function() {
          return saveRsvps()
            .then(saveSuccess)
            .catch(saveError)
        }

        function saveSuccess() {
          Alerts.success('SCHEDULE.RSVPS.success')
          $scope.rsvpsForm.$setPristine()
        }

        function saveError() {
          $scope.saveBarMessage = 'SCHEDULE.RSVPS.ERRORS.update_failed'
          $scope.saveButtonLabel = 'MODAL.retry'
          $scope.failed = true
        }

        $scope.saveBarMessage = 'SCHEDULE.RSVPS.unsaved_changes'
        $scope.saveButtonLabel = 'SCHEDULE.RSVPS.button_label'

        function saveRsvps() {
          if (!$scope.savedRsvps || !$scope.eventInvitations) return $q.when()
          return $q.all(_.map($scope.eventInvitations, saveRsvp))
        }

        function changedRsvp(invite) {
          var current = invite
          var saved = $scope.savedRsvps[invite.id]
          return current && (!saved || !angular.equals(saved, current))
        }

        function saveRsvp(invite) {
          if (!changedRsvp(invite)) return
          var rsvp = {
            ..._.pick(invite, 'event_id', 'persona_id', 'response', 'note'),
            team_id: $scope.team.id
          }
          return EventRsvp.create(rsvp)
            .then(updateSavedRsvp)
        }

        function updateSavedRsvp(invite) {
          $scope.savedRsvps[invite.id] = angular.copy(invite)
        }

        function indexInviteForEvent(event) {
          var invitesByPersona = $scope.invitesByEvent[event.unique_id] = $scope.invitesByEvent[event.unique_id] || {}
          return function(invite) {
            updateSavedRsvp(invite)
            $scope.eventInvitations.push(invite)
            invitesByPersona[invite.persona_id] = invite
            return invite
          }
        }

        async function getOrgSubvenues() {
          const subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' }).filter((i) => 'venue_name' in i)
          return subvenues
        }

        $scope.inviteCountForEvent = function(event, members) {
          var allInvites = $scope.invitesByEvent[event.unique_id]
          var memberIds = _.pluck(members, 'persona.persona_id')
          var memberInvites = _.compact(_.map(memberIds, _.propertyOf(allInvites)))
          var goingInvites = _.where(memberInvites, { response: 'yes' })
          return goingInvites.length + ' / ' + memberInvites.length
        }


        $scope.listenToRoot('event:save_recurring', load)


        $scope.memberTypes = Player.typesV3
        $scope.$watchGroup(['filteredEvents', 'members'], function(group) {
          if (!_.all(group)) return
          $scope.tableData = _.map($scope.memberTypes, function(type) {
            var members = _.where($scope.members, { roster_role: type })
            return {
              type,
              filter: { roster_role: type },
              members: members,
              rows: _.map(members, function(member) {
                return {
                  member: member,
                  cells: _.map($scope.filteredEvents, function(event) {
                    var invite = $scope.invitesByEvent[event.unique_id][member.persona.persona_id]
                    return tableCellData(event, invite)
                  })
                }
              })
            }
          })
        })

        function tableCellData(event, invite) {
          return {
            event: event,
            invite: invite
          }
        }

        $scope.inviteSpinnerActivated = function(event, member) {
          if ($scope.targetEvent && $scope.targetMember) {
            return (event.unique_id == $scope.targetEvent.unique_id && member.persona.persona_id == $scope.targetMember.persona.persona_id) ? true : false
          }
        }

        $scope.newMessageModal = function() {
          dialog.confirm({
            directive: 'new-team-message',
            attrs: {
              team: $scope.team,
              roster: $scope.team.roster(), // $scope.members passes undefined and does not get updated after load finishes
              recipients: _.pluck($scope.selectedMembers, 'persona.persona_id')
            }
          })
        }

        /** @deprecated */
        // TODO: Remove when Game modal is done and deployed to production
        const addGameUsingOldModal = (type = 'game') => {
          dialog.confirm({
            directive: `add-${type}`,
            scope: $scope,
            attrs: { team: $scope.team }
          })
            .then($scope.addEvent)
        }

        $scope.addGameModal = async function(type = 'game') {
          if (!launchDarklyFlags.gameAddEditModal) {
            return addGameUsingOldModal(type)
          }

          const modal = document.createElement('se-game-add-edit-modal')
          modal.seDataTriggerId = 'se-add-game-tc'
          document.body.append(modal)
          modal.addEventListener('seAfterClose', () => modal.remove())
          modal.addEventListener('seOnSuccess', () => loadEvents())
          modal.addEventListener('saveSubvenue', async () => {
            const subvenues = await getOrgSubvenues();
            modal.seSubvenues = JSON.stringify(subvenues);
          });

          const orgId = $scope.team.org_details.id
          let subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' })
          if($scope.resource?.subvenue_id && !subvenues.some(item => $scope.resource.subvenue_id === item.id)) {
            const missingVenue = await Subvenue.find($scope.resource.subvenue_id) || null
            subvenues.push(missingVenue)
          }
          const org = await Organization.findPublic(orgId)

          modal.seTeam = JSON.stringify($scope.team)
          modal.seOrg = JSON.stringify(org)
          modal.seSubvenues = JSON.stringify(subvenues.filter((i) => 'venue_name' in i))

          modal.seOpen = true
        }

        $scope.addEventModal = async function() {

          const modal = document.createElement('se-event-add-edit-modal')
          document.body.append(modal)
          modal.seDataTriggerId = 'se-add-event-tc'
          modal.addEventListener('seAfterClose', () => modal.remove())
          modal.addEventListener('seOnSuccess', () => loadEvents())
          modal.addEventListener('saveSubvenue', async () => {
            const subvenues = await getOrgSubvenues();
            modal.seSubvenues = JSON.stringify(subvenues);
          });

          const orgId = $scope.team.org_details.id
          let subvenues = await Subvenue.findAll({ org_id: orgId }, { load: 'all' })
          if($scope.resource?.subvenue_id && !subvenues.some(item => $scope.resource.subvenue_id === item.id)) {
            const missingVenue = await Subvenue.find($scope.resource.subvenue_id) || null
            subvenues.push(missingVenue)
          }
          const org = await Organization.findPublic(orgId)

          modal.seTeam = JSON.stringify($scope.team)
          modal.seOrg = JSON.stringify(org)
          modal.seSubvenues = JSON.stringify(subvenues.filter((i) => 'venue_name' in i) || [])

          modal.seOpen = true
        }

        $scope.addEvent = function(event) {
          $scope.events.push(event)
          _.each(event.event_attendees, indexInviteForEvent(event))
        }

        $scope.getMemberType = function(member) {
          if (member.roster_role === 'player') {
            return 'Player'
          }
          else if (member.roster_role === 'staff') {
            return 'Staff'
          }
        }

        $scope.getContextForShowingRsvp = group => `rsvp${ group?.type === 'staff' ? 'Staff' : 'Participant' }`

      }
    }
  })
